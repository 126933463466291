import React, { useState } from 'react';
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  ShowController,
  DateField,
} from 'react-admin';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { PostEditActions } from 'src/components/TopToolbar';
import InvoiceDocumentField from 'src/components/InvoiceDocumentField';
import ConncetedInvoiceField from 'src/components/ConncetedInvoiceField';
import GetListSubCollection from 'src/components/GetListSubCollection';
import Transactions from 'src/components/Transactions';

import OfferForm from './components/OfferForm';
import ShowOffer from './components/ShowOffer';
import DocumentUpload from './components/DocumentUpload';

const useStyles = makeStyles(theme => ({
  documentActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const InvoiceTitle = ({ record }: any) => {
  return <span>{record && record.id}</span>
}

const InvoiceShow = (props: any) => {
  const [showOfferForm, toggleShowOfferForm] = useState(false);
  const [openDocumentUploadDialog, setOpenDocumentUploadDialog] = useState(false);
  const classes = useStyles();

  return (
    <ShowController {...props}>
      {(controllerProps: any) => {
        return (
          <>
            <OfferForm
              showOfferForm={showOfferForm}
              toggleShowOfferForm={toggleShowOfferForm}
              {...props}
            />
            <Show
              title={<InvoiceTitle data={props.data} {...props} />}
              actions={<PostEditActions customAction={() => toggleShowOfferForm(true)} />}
              {...props}
              {...controllerProps}
            >
              <TabbedShowLayout>
                <Tab label="Summary">
                  <TextField source="referenceNumber" />
                  <TextField source="purpose" />
                  <TextField source="requestedAmount" />
                  <TextField source="remainingAmount" />
                  <TextField source="initiatedBy.type" label="Type" />
                  <TextField source="deliveryTime" />
                  <TextField source="buyerConfirmed" />
                  <TextField source="sellerConfirmed" />
                  <TextField source="offerCreated" />
                  <DateField source="nextDueDate" />
                  <TextField source="status" />
                  {controllerProps.record && controllerProps.record.initiatedBy.permissions && (
                    <h4>Permissions</h4>
                  )}
                  {controllerProps.record && controllerProps.record.initiatedBy.permissions && (
                    <TextField source="initiatedBy.permissions.payForBuyer" label="Pay for Buyer" />
                  )}
                </Tab>
                <Tab label="Initiated By">
                  <TextField source="initiatedBy.merchantId" label="Merchant Id" />
                  <TextField source="initiatedBy.companyName" label="Company Name" />
                  <TextField source="initiatedBy.type" label="Type" />
                  <TextField source="initiatedBy.userId" label="User Id" />
                </Tab>
                <Tab label="Request To">
                  <TextField source="requestTo.companyName" label="Company Name" />
                  <TextField source="requestTo.email" label="Email" />
                  <TextField source="requestTo.phone" label="Phone" />
                </Tab>
                <Tab label="Confirmation">
                  <TextField source="buyerConfirmed" />
                  <TextField source="sellerConfirmed" />
                </Tab>
                <Tab label="Documents">
                  <div className={classes.documentActionContainer}>
                    <DocumentUpload
                      open={openDocumentUploadDialog}
                      onClose={() => setOpenDocumentUploadDialog(false)}
                      {...props}
                    />
                    <Button
                      startIcon={<AttachFileIcon />}
                      color="primary"
                      onClick={() => setOpenDocumentUploadDialog(true)}
                    >
                      Upload
                    </Button>
                  </div>
                  <ConncetedInvoiceField label="Connected Invoice" />
                  <InvoiceDocumentField type="contract" label="Contract" />
                  <InvoiceDocumentField type="invoices" label="Invoices" />
                  <InvoiceDocumentField type="additional" label="Additional documents" />
                  <InvoiceDocumentField type="transfer_record" label="Transfer Records" />
                  <InvoiceDocumentField type="repayment_record" label="Repayment Records" />
                </Tab>
                <Tab label="Transactions">
                  <GetListSubCollection
                    collection="invoices"
                    subcollection="transactions"
                  >
                    <Transactions
                      invoiceId={controllerProps.record ? controllerProps.record.id : ''}
                    />
                  </GetListSubCollection>
                </Tab>
                {controllerProps.record && controllerProps.record.offerCreated && (
                  <Tab label="Offer">
                    <ShowOffer
                      subcollection="offer"
                      record={controllerProps.record}
                    />
                  </Tab>
                )}
              </TabbedShowLayout>
            </Show>
          </>
        )
      }}
    </ShowController>
  )
};

export default InvoiceShow;
